<template>
  <div class="card card_bg" @click="toOrderDetail(item)">
    <div class="card-header">
      <span class="suyun">{{ item.orderTypeName }}</span>
      <span class="order">订单 {{ item.orderCode }}</span>
      <span :class="'status ' + item.color">{{ item.orderStatusName }}</span>
    </div>
    <div class="card-middle">
      <div class="li">
        <span>收货人</span>
        <span v-show="item.rcvPhone">
          <a :href="'tel:' + item.rcvPhone" @click.stop>
            <span>{{ item.rcvName }} {{ item.encryptionUserPhone }}</span>
            <img class="revPhone" src="../../assets/imgs/revPhone.png" />
          </a>
        </span>
      </div>
      <div class="li">
        <span>车辆编号</span>
        <span>
          <span v-if="item.vinCode">{{ item.vinCode }}({{ item.vinId }})</span>
          <span v-else>{{ item.vinId ? item.vinId : "暂未匹配车辆" }}</span>
        </span>
      </div>
      <div v-if="item.rankNumber" class="li">
        <span>格口号</span>
        <span>
          <span>{{ item.rankNumber }}</span>
        </span>
      </div>
      <div class="li">
        <span>订单金额</span>
        <span>
          <i>￥</i>
          {{ item.totalPrice }}
        </span>
      </div>
      <div class="li">
        <span>下单时间</span>
        <span>{{ item.dateTime }}</span>
      </div>
    </div>

    <div class="card-bottom">
      <span
        class="canceled"
        v-if="item.orderStatus === 1 || item.orderStatus === 14"
        @click.stop="cancelOrder(item.orderCode || '')"
      >
        取消订单
      </span>
      <a
        class="card_phone_color phone_border_color"
        @click.stop
        href="tel:400-666-7876"
      >
        联系客服
      </a>
      <span
        class="commented"
        v-if="item.showOpenDoor"
        @click.stop="openDoor(item)"
      >
        开门投柜
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCard",

  data() {
    return {};
  },

  props: {
    item: {
      type: Object,
      default: {},
    },
  },

  watch: {},

  mounted: function () {},

  created: function () {},

  methods: {
    toOrderDetail(data) {
      window.location.href = `neolixjs://orderDetails?orderCode=${data.orderCode}`;
    },
    toRefundRecord() {
      this.$route.push({
        path: "../Refund/index",
        query: {
          id: this.item.id,
        },
      });
    },

    openDoor(data) {
      this.$emit("handleOpenDoor", data);
    },

    // toPhone() {
    //   console.log(this);
    // },

    cancelOrder(orderCode) {
      this.$emit("cancelOrder", orderCode);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card_bg {
  @include background_color("card_bg");
}

.card_header_color {
  @include font_color("card_header_color");
}

.card_date_color {
  @include font_color("card_date_color");
}

.card_price_color {
  @include font_color("card_price_color");
}

.card_phone_color {
  @include font_color("card_phone_color");
}

.phone_border_color {
  @include border_color("phone_border_color");
}

.imgBox_bg_image {
  @include bg_image("imgBox_bg_image");
}

.card {
  width: calc(100% - 0.6rem);
  padding: 0.12rem 0.3rem;
  margin: 0.3rem;
  border-radius: 0.24rem;
  .card-header {
    width: 100%;
    height: 0.64rem;
    line-height: 0.64rem;
    .suyun {
      display: inline-block;
      height: 0.42rem;
      line-height: 0.42rem;
      padding: 0 0.16rem;
      font-size: 0.2rem;
      color: #18a4ff;
      float: left;
      border: 0.02rem solid rgba(24, 164, 255, 1);
      border-radius: 0.06rem;
      margin-right: 0.08rem;
      margin-top: 0.1rem;
      font-weight: 600;
    }
    .order {
      font-size: 0.32rem;
      color: #000000;
      font-weight: 800;
      float: left;
    }
    .status {
      font-size: 0.28rem;
      float: right;
      font-weight: 600;
    }
    .cFC7E36 {
      color: #fc7e36;
    }
    .c436EFF {
      color: #436eff;
    }
    .c26C575 {
      color: #26c575;
    }
    .c000000 {
      color: #000000;
    }
    .c9798AB {
      color: #9798ab;
    }
  }

  .card-middle {
    .li {
      font-size: 0.28rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.16rem 0;
      > :first-child {
        color: #9798ab;
      }
      > :last-child {
        color: #000000;
      }
      span a {
        text-decoration-line: none;
        color: #000000;
      }
      span i {
        font-style: normal;
        font-size: 0.24rem;
      }
      span .revPhone {
        width: 0.4rem;
        height: 0.4rem;
        float: right;
        margin-left: 0.12rem;
      }
    }
  }

  .card-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.3rem;
    padding-bottom: 0.24rem;
    padding-top: 0.12rem;
    margin-top: 0.2rem;

    span,
    a {
      padding: 0.11rem 0.24rem;
      border-width: 0.01rem;
      border-style: solid;
      border-radius: 0.3rem;
      font-size: 0.26rem;
      font-weight: 500;
      margin-left: 0.24rem;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      [data-theme="light"] &:active {
        background: #d9d9d9;
      }
    }

    .commented {
      background: #436eff;
      border-radius: 0.32rem;
      border: none;
      color: #ffffff;
    }

    .canceled {
      border: 0.01rem solid rgba(213, 214, 222, 1);
    }
  }
}
</style>
