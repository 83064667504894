<template>
  <div class="contain bg" ref="contains">
    <div class="myOrder title_bg title_color" ref="refHeight1">
      <span style="flex: 1">
        <img
          class="searchBack"
          @click="handleBack"
          src="../../assets/imgs/goback.png"
          alt=""
        />
      </span>
      <span style="flex: 1">已投柜</span>
      <span class="searchInfo">
        <span class="searchInfoSpan" v-if="tabType === 'right'">
          <img
            class="searchPic"
            @click.stop="goSearch"
            src="../../assets/imgs/search.png"
            alt=""
          />
          <img
            v-if="privacyCheck.length > 0"
            class="searchPic"
            @click.stop="handleScreen"
            src="../../assets/imgs/searchBlue.png"
            alt=""
          />
          <img
            v-else
            class="selectPic"
            src="../../assets/imgs/select.png"
            alt=""
            @click.stop="handleScreen"
          />
        </span>
      </span>
    </div>
    <!--<Tab @handleTab="handleTab" :defaultTab="queryStatus" :tabList="tabList" />-->
    <!-- 下拉刷新 -->
    <div class="web" ref="refHeight">
      <PullDownRefresh2
        @refresh="refresh"
        class="pullRefreshScroll"
        style="margin-top: -1.12rem"
      >
        <div class="orderList" ref="orderList">
          <div class="orderListContent">
            <div
              class="info"
              v-if="tabType === 'right' && orderList.length != 0"
            >
              <span class="total">
                共
                <span class="totalNumber">{{ total }}</span>
                单
              </span>
              <span
                class="multipleSelect"
                v-if="multipleSelect"
                @click="enableMulipleSelect(false)"
                >取消</span
              >
              <span
                class="multipleSelect"
                v-else
                @click="enableMulipleSelect(true)"
                >批量选择</span
              >
            </div>
            <!-- 空数据 -->
            <NoData v-if="emptyDataFlag" />
            <!-- 有数据 -->
            <div v-if="!emptyDataFlag">
              <NewOrderCard
                :item="item"
                v-for="item in orderList"
                :key="item.orderCode"
                :installed="true"
                :selectable="multipleSelect"
                :selectedMap="selectedMap"
                @onselect="handleItemSelect"
                @onmodifyStation="handleItemModifyStation"
                @secondSend="handleSecondSendOrder"
                @cancelDelivery="cancelDelivery"
                @deleteOrder="deleteOrder"
                @toPhone="handlePhone"
              />
            </div>
          </div>
        </div>
      </PullDownRefresh2>
    </div>
    <SearchList
      v-if="searchListFlag"
      :from="'installedOrderlist'"
      :queryObject="queryObject"
      @handleSearchCancel="handleSearchCancel"
    />
    <PopSelect
      :styleObj="style"
      :triangle="triangle"
      :showFlag="showFlag"
      :selectList="selectList"
      :defaultValue="defaultValue"
      @handleSelect="handleSelectSet"
      @handleClose="handleScreen"
    />
    <!-- 评价 -->
    <Evaluation v-if="commentedShow" :show="commentedShow" />
    <Dialog
      v-if="showDialog"
      :dialogContent="dialogContent"
      :dialogTitle="dialogTitle"
      :dialogCancel="dialogCancel"
      :dialogEnter="dialogEnter"
      @handleOk="handleOk"
      @handleCancel="handleCancel"
    />
    <div
      v-if="multipleSelect && orderList.length !== 0"
      class="multipleSelectPannel"
    >
      <div>
        <img
          @click="handleSelectAll(false)"
          v-if="selectAll"
          src="../../assets/imgs/selected.png"
          alt=""
        />
        <img
          @click="handleSelectAll(true)"
          v-else
          src="../../assets/imgs/unselected.png"
          alt=""
        />
        <span>
          已选
          <span class="colorfulNumber">{{ selectedCount }}</span>
          单
        </span>
      </div>
      <div>
        <div
          class="modifyBtn"
          :class="{ disabled: selectedCount === 0 }"
          @click="modifyMultipleStation"
        >
          修改收货点
        </div>
        <div
          @click="cancelMultipleDelivery"
          :class="{ disabled: selectedCount === 0 }"
          class="cancelBtn"
        >
          取消配送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import moment from "moment";
import OrderCard from "./OrderCard.vue";
import NewOrderCard from "./NewOrderCard.vue";
import NoData from "./NoData.vue";
import Evaluation from "./Evaluation.vue";
import PullDownRefresh2 from "@/components/PullDownRefresh2";
// import Tab from "./Tab.vue";
import Dialog from "@/components/Dialog.vue";
import Message from "vue-m-message";
import PopSelect from "./PopSelect.vue";
import SelectList from "./SearchList.vue";
import NDialog from "@/components/NDialog";
// import DialogAbn from "./DialogList.vue";
// import BScroll from "better-scroll";
import {
  deliveryPageList,
  cancelOrder,
  openDoor,
  secondDelivery,
  offlineDelivery,
  selectPageList,
  // getAbnList,
  // justOpenDoor,
  getInstalledOrderList,
  cancelDeliveryRequest,
  deleteOrderRequest,
} from "@/api/api";
import { batchCancelDelivery } from "@/api/apiv2";
import { leftList, rightList, selectList } from "@/config/index";
import SearchList from "./SearchList.vue";
import { modifyDeliveryStation } from "@/utils/index";
import { Toast } from "vant";

export default {
  name: "InstalledOrderList",
  components: {
    OrderCard,
    NewOrderCard,
    NoData,
    Evaluation,
    PullDownRefresh2,
    PopSelect,
    // Tab,
    Dialog,
    SelectList,
    SearchList,
    // DialogAbn,
  },
  data() {
    return {
      style: {
        box: {
          position: "absolute",
          top: "0.9rem",
          left: "4.3rem",
        },
        triangle: {
          position: "absolute",
          top: "0.79rem",
          left: "6.75rem",
        },
      },
      height: 0,
      selectList: selectList,
      defaultValue: 2,
      selectValue: "全部",
      orderList: [],
      total: 0,
      privacyCheck: [],
      triangle: "right",
      emptyDataFlag: false,
      commentedShow: false,
      showFlag: false,
      scrollTop: 0,
      queryStatus: 0, // 0-全部 1-进行中  2-已取消 4-已完成  0-待配送 1-配送中  2-已完成 3-异常件
      pageNum: 1,
      pageSize: 100,
      showDialog: false,
      dialogType: "cancelOrder",
      dialogContent: "确认取消订单吗？",
      dialogCancel: "取消",
      dialogEnter: "确定",
      dialogTitle: "提示",
      cancelOrderCode: "", //  取消订单号
      secondSendOrderCode: "", // 二次配送单号
      openOrderCode: "",
      confirmReturnCode: "", //退库原因选项的code
      confirmReturnDesc: "", //退库原因描述
      orderAddressInfoId: "",
      stationId: "",
      orderDetail: {},
      openVin: "",
      timer: null,
      tabList: leftList,
      tabType: "right",
      leftQueryStatus: 0,
      rightQueryStatus: 0,
      searchListFlag: false,
      queryObject: {
        queryStatus: -1,
        privacyCheck: JSON.stringify(this.privacyCheck),
      },
      abnList: [],
      showDialogAbn: false,
      multipleSelect: false,
      selectedMap: {},
    };
  },

  computed: {
    selectAll() {
      const len = Object.keys(this.selectedMap).length;
      return len && len === this.orderList.length;
    },
    selectedCount() {
      return Object.keys(this.selectedMap).length;
    },
  },

  mounted: function () {},

  created: function () {
    this.queryStatus = 0;
    this.height = window.innerHeight;
    // this.tabType = this.$route.query.tabType || "left";
    this.tabType = "right";
    this.$loadingCircle.start();
    const query = this.$router.history.current.query;
    query.token && sessionStorage.setItem("_token", query.token);
    query.userid && sessionStorage.setItem("_userId", query.userId);
    query.stationId && (this.stationId = query.stationId);
    query.orderAddressInfoId &&
      (this.orderAddressInfoId = query.orderAddressInfoId);

    this.getOrderList();
    this.timer = setInterval(() => {
      this.getOrderList();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    // 触发刷新，搜索时初始化多选
    initMultipleSelect() {
      this.multipleSelect = false;
      this.selectedMap = {};
    },
    modifyMultipleStation() {
      if (!this.selectedCount) return;
      const data = {
        multiple: 1,
        list: Object.values(this.selectedMap).map((item) => ({
          vin: item.vehicleVin,
          vinId: item.vinId,
          stationId: item.rcvStationId,
          stationName: item.rcvStationName,
          id: item.id,
          expressName: item.expressCompany,
          expressCompanyId: item.expressCompanyId,
          rcvLat: item.rcvLat,
          rcvLng: item.rcvLng,
        })),
      };
      modifyDeliveryStation(JSON.stringify(data));
    },
    handleItemModifyStation(item) {
      const {
        vehicleVin: vin,
        vinId,
        rcvStationId: stationId,
        rcvStationName: stationName,
        id,
        expressCompany: expressName,
        expressCompanyId,
        rcvLat,
        rcvLng,
      } = item;
      modifyDeliveryStation(
        JSON.stringify({
          multiple: 0,
          list: [
            {
              vin,
              vinId,
              stationId,
              stationName,
              id,
              expressName,
              expressCompanyId,
              rcvLat,
              rcvLng,
            },
          ],
        })
      );
    },
    async cancelMultipleDelivery() {
      if (this.selectedCount) {
        const message =
          "确定后，二次配送的订单状态将变为【异常件】，格口不会打开";
        const status = await NDialog.confirm({
          title: "确定取消配送吗？",
          message,
        });
        if (status === "confirm") {
          const res = await batchCancelDelivery({
            ids: Object.keys(this.selectedMap),
          });
          if (res === null) {
            if (this.orderList.length - this.selectedCount <= 0) {
              this.handleBack();
            }
            Toast({
              message: "操作成功",
              className: "response-toast",
            });
          }
          this.selectedMap = {};
          this.getOrderList();
        }
      }
    },
    handleSelectAll(selected) {
      if (selected) {
        this.orderList.forEach((item) => {
          if (!this.selectedMap[item.id]) {
            this.$set(this.selectedMap, item.id, item);
          }
        });
      } else {
        this.selectedMap = {};
      }
    },
    handleItemSelect(item) {
      if (this.selectedMap[item.id]) {
        this.$delete(this.selectedMap, item.id);
      } else {
        this.$set(this.selectedMap, item.id, item);
      }
    },
    enableMulipleSelect(status) {
      this.multipleSelect = status;
      if (status === false) {
        this.selectedMap = {};
      }
    },
    handleSearchCancel() {
      this.searchListFlag = false;
      this.getOrderList();
    },
    handleBack() {
      window.location.href = "neolix://interaction";
    },
    async stopTouchMove() {
      await nextTick();
      const layerBox = this.$refs.orderList;
      var targetY = null;
      layerBox.addEventListener("touchstart", function (e) {
        //clientY-客户区坐标Y 、pageY-页面坐标Y
        targetY = Math.floor(e.targetTouches[0].clientY);
      });
      layerBox.addEventListener(
        "touchmove",
        function (e) {
          // 检测可滚动区域的滚动事件，如果滑到了顶部或底部，阻止默认事件
          var NewTargetY = Math.floor(e.targetTouches[0].clientY), //本次移动时鼠标的位置，用于计算
            sTop = layerBox.scrollTop, //当前滚动的距离
            sH = layerBox.scrollHeight, //可滚动区域的高度
            lyBoxH = layerBox.clientHeight; //可视区域的高度
          if (sTop <= 0 && NewTargetY - targetY > 0 && "鼠标方向向下-到顶") {
            console.log("条件1成立：下拉页面到顶");
            e.preventDefault();
          } else if (
            sTop >= sH - lyBoxH &&
            NewTargetY - targetY < 0 &&
            "鼠标方向向上-到底"
          ) {
            console.log("条件2成立：上翻页面到底");
            e.preventDefault();
          } else {
            console.log("ccccc");
          }
        },
        false
      );
    },
    // init() {
    //   this.bs = new BScroll(this.$refs.scroll, {
    //     probeType: 3,
    //     click: true,
    //   });
    // },

    handleClosePop() {
      this.showFlag = !this.showFlag;
    },
    handleScreen() {
      this.showFlag = !this.showFlag;
      this.initMultipleSelect();
    },
    handleSelectSet(data) {
      this.selectValue = data.value;
      if (data.key === 2) {
        this.privacyCheck = [];
      } else {
        this.privacyCheck = [];
        this.privacyCheck.push(data.key);
      }

      this.placeholderValue = "请输入" + data.value;
      this.showFlag = !this.showFlag;
      this.getSelectPageList();
    },
    getToken() {
      return sessionStorage.getItem("_token");
    },
    getUserId() {
      return sessionStorage.getItem("_userId");
    },
    goSearch() {
      this.initMultipleSelect();
      this.searchListFlag = true;
      this.queryObject = {
        queryStatus: -1,
        privacyCheck: JSON.stringify(this.privacyCheck),
        orderAddressInfoId: this.orderAddressInfoId,
        stationId: this.stationId,
      };
    },
    handleType(type) {
      this.$loadingCircle.start();
      if (type === "right") {
        this.queryStatus = this.rightQueryStatus;
        this.getSelectPageList();
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.getSelectPageList();
        }, 60000);
      } else {
        this.queryStatus = this.leftQueryStatus;
        this.tabList = leftList;
        this.getOrderList();
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.getOrderList();
        }, 60000);
      }
      this.tabType = type;
    },
    getSelectPageList(done) {
      const params = {
        privacyCheck: this.privacyCheck,
        queryStatus: -1, // 放开注释 防止页面刷新展示其他的单子
        orderAddressInfoId: this.orderAddressInfoId,
        rcvStationId: this.stationId,
        userId: this.getUserId(),
        vinId: "",
        expressNo: "",
        rcvStationName: "",
        rcvPhone: "",
        pageSize: this.pageSize,
        pageNo: 1,
      };
      // if (this.queryStatus === 2) {
      params.endDate = moment().locale("zh-cn").format("YYYY-MM-DD");
      params.startDate = moment().add(-6, "d").format("YYYY-MM-DD");
      // }
      selectPageList({
        ...params,
      })
        .then((res) => {
          done && done();
          this.orderList = res.list;
          this.total = res.total;
          rightList[rightList.length - 1].abnormalCount = res.abnormalCount;
          this.tabList = rightList;
          if (this.orderList.length > 0) {
            this.emptyDataFlag = false;
          } else {
            this.emptyDataFlag = true;
          }
          this.orderList.map((item) => {
            if (item.rcvPhone) {
              item.encryptionUserPhone = item.rcvPhone;
            }
            switch (item.expressStatus) {
              case 0:
                item.color = "cFC7E36";
                break;
              case 1:
                item.color = "c436EFF";
                break;
              case 3:
                item.color = "c00CB95";
                break;
              case 4:
                item.color = "cFF391B";
                break;
              default:
                item.color = "c436EFF";
                break;
            }
          });
        })
        .finally(() => {
          this.tabList = rightList;
          this.$loadingCircle.end();
        })
        .catch((err) => {
          this.orderList = [];
          this.emptyDataFlag = true;
        });
    },
    getOrderList(done) {
      getInstalledOrderList({
        userId: sessionStorage.getItem("_userId"),
        // userId: 1,
        queryStatus: -1, //接口文档规定本次传0
        orderAddressInfoId: this.orderAddressInfoId,
        rcvStationId: this.stationId,
        pageNum: 0,
        pageSize: this.pageSize,
        endDate: moment().locale("zh-cn").format("YYYY-MM-DD"),
        startDate: moment().add(-7, "d").format("YYYY-MM-DD"),
      })
        .then((res) => {
          done && done();
          this.orderList = res.list;
          this.total = res.total;
          rightList[rightList.length - 1].abnormalCount = res.abnormalCount;
          this.tabList = rightList;
          this.emptyDataFlag = this.orderList.length <= 0;
          this.orderList.map((item) => {
            if (item.rcvPhone) {
              item.encryptionUserPhone = item.rcvPhone;
            }
            switch (item.expressStatus) {
              case 0:
                item.color = "cFC7E36";
                break;
              case 1:
                item.color = "c436EFF";
                break;
              case 3:
                item.color = "c00CB95";
                break;
              case 4:
                item.color = "cFF391B";
                break;
              default:
                item.color = "c436EFF";
                break;
            }
          });
        })
        .finally(() => {
          this.$loadingCircle.end();
        })
        .catch((err) => {
          this.orderList = [];
          this.emptyDataFlag = true;
        });
    },
    handleTab(data) {
      this.queryStatus = data.key;
      this.$refs.refHeight.scrollTop = 0;
      if (this.tabType === "left") {
        this.leftQueryStatus = data.key;
        this.getOrderList();
      } else {
        this.rightQueryStatus = data.key;
        this.getSelectPageList();
      }
    },
    // 打电话
    handlePhone(item) {
      let encryptionUserPhone = item.encryptionUserPhone;
      if (encryptionUserPhone.includes("-")) {
        const phoneItem = encryptionUserPhone.split("-");
        this.phoneNumber = phoneItem[0];
        this.dialogCancel = "暂不拨打";
        this.dialogEnter = "确定拨打";
        this.dialogTitle = "确定拨打电话吗？";
        this.dialogContent = "请在电话拨通后拨转接号" + phoneItem[1];
        this.showDialog = true;
        this.dialogType = "telphone";
      } else {
        this.phoneNumber = encryptionUserPhone;
        this.telphone();
      }
    },

    // 二次配送
    handleSecondSendOrder(orderCode) {
      this.secondSendOrderCode = orderCode;
      this.dialogTitle = "确定二次配送该快递吗？";
      this.dialogContent =
        "确定后，订单状态将恢复为“待配送”，可为车辆重新下发配送任务";
      this.showDialog = true;
      this.dialogType = "secondSendOrder";
    },

    // 线下配送
    handleOfflineDelivery(data) {
      console.log("data", data);
      this.orderDetail = data;
      this.offlineDeliveryOrderCode = data.packageNo;
      this.vinId = data.vehicleVin;
      this.cabinCode = data.cabinCode;
      // 0 - 摩西系统 1 - 小兵系统
      if ([0, 1].includes(data.expressSource)) {
        // if (true) {
        this.showDialog = true;
        this.dialogTitle = "线下配送";
        this.dialogContent = "是否执行【驿站退库】操作？";
        this.dialogCancel = "仅取出，不退库";
        this.dialogEnter = "退库";
        this.dialogType = "expressOfflineDelivery";
        this.getAbnList(data); //拉取退库原因
      } else {
        this.dialogTitle = "确定线下配送该快递吗？";
        this.dialogContent =
          "确定后，格口将自动打开，请取出包裹通过其他线下方式完成配送";
        this.showDialog = true;
        this.dialogType = "offlineDelivery";
      }
    },

    // 取消订单
    handleCancelOrder(orderCode) {
      this.cancelOrderCode = orderCode;
      this.dialogContent = "确定取消订单吗？";
      this.showDialog = true;
      this.dialogType = "cancelOrder";
    },

    // 取消配送
    async cancelDelivery(item) {
      const message = item.secondaryCheck
        ? "确定后，二次配送的订单状态将变为【异常件】，格口不会打开"
        : "确定后，请至打开的格口中取走快包裹";
      const status = await NDialog.confirm({
        title: "确定取消配送吗？",
        message,
      });
      if (status === "confirm") {
        const res = await batchCancelDelivery({ ids: [item.id] });
        if (res === null) {
          if (this.orderList.length - 1 <= 0) {
            this.handleBack();
          }
          Toast({
            message: "操作成功",
            className: "response-toast",
          });
        }
        this.initMultipleSelect();
        this.getOrderList();
      }
      // this.orderDetail = item;
      // this.dialogContent = "确定取消该订单二次配送？订单状态将变为【异常件】";
      // this.showDialog = true;
      // this.dialogType = "cancelDelivery";
    },

    // 删除订单
    deleteOrder(item) {
      this.orderDetail = item;
      this.dialogContent = "确定删除该订单吗？";
      this.showDialog = true;
      this.dialogType = "deleteOrder";
    },

    // 打开柜门
    handleOpenDoor(data) {
      this.openOrderCode = data.orderCode;
      this.openVin = data.vehicleVin;
      this.dialogContent = "确定打开柜门吗？";
      this.showDialog = true;
      this.dialogType = "openDoor";
    },

    handleOk() {
      this.showDialog = false;
      if (this.dialogType === "cancelOrder") {
        this.cancelOrder();
      } else if (this.dialogType === "openDoor") {
        this.openDoor();
      } else if (this.dialogType === "secondSendOrder") {
        this.secondSendOrder();
      } else if (this.dialogType === "offlineDelivery") {
        this.offlineDelivery();
      } else if (this.dialogType === "telphone") {
        this.telphone();
      } else if (this.dialogType === "expressOfflineDelivery") {
        this.showDialogAbn = true;
        this.dialogTitle = "请选择退库原因";
        // this.dialogContent = "是否执行【驿站退库】操作？";
        this.dialogCancel = "取消";
        this.dialogEnter = "确定退库";
        this.dialogType = "confirmReturn";
      } else if (this.dialogType === "confirmReturn") {
        console.log("确定退库");
        this.takeOut(2);
      } else if (this.dialogType === "cancelDelivery") {
        console.log("取消配送");
        this.cancelDeliveryRequest();
      } else if (this.dialogType === "deleteOrder") {
        console.log("删除订单");
        this.deleteOrderRequest();
      }
    },

    handleCancel(flag = false) {
      console.log("flag:", flag);
      if (!flag && this.dialogType === "expressOfflineDelivery") {
        console.log("仅取出，不退库");
        // 仅取出，不退库
        this.takeOut(1);
      } else {
        this.showDialog = false;
        this.showDialogAbn = false;
      }
    },

    //切换退库原因
    switchOption(i) {
      this.abnList.map((v, n) => {
        if (n === i) {
          v.check = true;
          this.confirmReturnCode = v.code;
          this.confirmReturnDesc = v.desc;
        } else {
          v.check = false;
        }
      });
      this.abnList = [...this.abnList];

      // console.log(this.abnList);
    },

    // 二次配送订单
    secondSendOrder() {
      secondDelivery({
        packageNo: this.secondSendOrderCode,
      })
        .then((res) => {
          this.showDialog = false;
        })
        .finally(() => {
          this.getSelectPageList();
        })
        .catch((err) => {
          // Message({
          //   title: err.msg,
          //   type: "success",
          //   collapsable: false,
          // });
        });
    },
    // 拨打电话
    telphone() {
      window.location.href = "tel:" + this.phoneNumber;
    },

    // 线下配送
    offlineDelivery(data) {
      console.log("订单数据：", data);
      offlineDelivery({
        packageNo: this.offlineDeliveryOrderCode,
        vin: this.vinId,
        cabinCode: this.cabinCode,
      })
        .then((res) => {
          this.showDialog = false;
        })
        .finally(() => {
          this.getSelectPageList();
        })
        .catch((err) => {});
    },

    //取消配送
    cancelDeliveryRequest() {
      console.log(this.orderDetail);
      cancelDeliveryRequest({
        packageNo: this.orderDetail.packageNo, //包裹订单号
      })
        .then((res) => {
          if (this.orderList.length - 1 <= 0) {
            this.handleBack();
          }
          this.showDialog = false;
        })
        .finally(() => {
          this.showDialog = false;
          this.getOrderList();
        });
    },

    //删除订单
    deleteOrderRequest() {
      console.log(this.orderDetail);
      deleteOrderRequest({
        vin: this.orderDetail.vehicleVin,
        id: this.orderDetail.id, //包裹订单号
      })
        .then((res) => {
          this.showDialog = false;
          if (this.orderList.length - 1 <= 0) {
            this.handleBack();
          }
        })
        .finally(() => {
          this.showDialog = false;
          this.getOrderList();
        });
    },

    // 取消订单
    cancelOrder() {
      this.$loadingCircle.start();
      cancelOrder({
        orderCode: this.cancelOrderCode,
      })
        .then((res) => {
          this.showDialog = false;
          Message({
            title: "订单已取消，即将退款！",
            type: "success",
            collapsable: false,
          });
          this.getOrderList();
        })
        .catch((err) => {});
    },

    // 打开柜门
    openDoor() {
      this.$loadingCircle.start();
      openDoor({
        orderCode: this.openOrderCode,
        vin: this.openVin,
      })
        .then((res) => {
          this.showDialog = false;
          this.$router.push({
            path: "/openDoor",
            query: {
              orderCode: this.openOrderCode,
              vin: this.openVin,
              countdownTime: res.countdownTime,
            },
          });
        })
        .catch((err) => {
          // Message({
          //   title: err.msg,
          //   type: "success",
          //   collapsable: false,
          // });
        });
    },

    refresh(done) {
      // 注释放开 解决筛选时选择隐私面单或非隐私，下拉刷新返回全部的单子
      if (this.tabType === "left") {
        this.getOrderList(done);
      } else {
        this.getSelectPageList(done);
      }
      this.initMultipleSelect();
      // this.getSelectPageList(done);
      // this.getOrderList(done)
    },

    handleScroll() {
      let scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
      let oneHeight = this.$refs.refHeight.offsetHeight;
      if (-oneHeight >= scrollTop) {
        this.utils.setTitle("我的订单");
      } else {
        this.utils.setTitle("");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  @include background_color("bg");
}
.title_bg {
  @include background_color("title_bg");
}
.title_color {
  @include font_color("title_color");
}
.tip_color {
  @include font_color("tip_color");
}

.contain {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 0.8rem;
  .searchInfo {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    position: relative;
    .searchInfoSpan {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 0.8rem;
      position: absolute;
      right: 0.15rem;
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin: 0 0.16rem;
      }
    }
  }
  .info {
    display: flex;
    font-size: 0.3rem;
    height: 0.48rem;
    line-height: 0.48rem;
    justify-content: center;
    margin: 0.24rem 0.3rem 0.24rem 0.24rem;
    position: relative;
    .total {
      color: #9798ab;
      font-size: 0.26rem;
    }
    .totalNumber {
      color: #fc7e36;
      font-weight: 600;
      font-size: 0.26rem;
      margin: 0 0.08rem;
    }
    .space {
      width: 1.28rem;
    }
    img {
      width: 0.48rem;
      height: 0.48rem;
      margin: 0 0.16rem;
    }
    .multipleSelect {
      position: absolute;
      right: 0px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(67, 110, 255, 1);
    }
  }
  .active {
    color: #436eff !important;
    span {
      display: inline-block;
      width: 0.84rem;
      height: 0.06rem;
      border-radius: 0.06rem;
      background: #436eff;
      position: absolute;
      bottom: 0.04rem;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .searchBack {
    text-align: center;
    display: inline-block;
    color: #7a7b92;
    width: 0.64rem;
    height: 0.64rem;
    position: absolute;
    top: 0.07rem;
    left: 0.24rem;
  }
  .tabLeft,
  .tabRight {
    width: 2rem;
    text-align: center;
    display: inline-block;
    color: #7a7b92;
    position: relative;
  }
  .myOrder {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.3rem;
    margin: 0 auto;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
    display: flex;
  }

  .web {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    //overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    .pullRefreshScroll {
      min-height: calc(100% + 1px);
    }
    .orderList {
      width: 100%;
      padding-bottom: 0.2rem;

      @include background_color("bg");
      .orderListContent {
        padding-bottom: 46px;
        // min-height: calc(100% + 1px)
      }
      .tip {
        height: 0.93rem;
        line-height: 0.93rem;
        /*margin-bottom: .30rem;*/
        font-size: 0.24rem;
        color: #aeb5ca;
        text-align: center;

        & + .order {
          margin-top: 0;
        }
      }
    }
  }
}
.multipleSelectPannel {
  font-size: initial;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: white;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
  > div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(0, 0, 0, 1);
      font-weight: 600;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      .colorfulNumber {
        color: #426eff;
      }
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      div + div {
        margin-left: 12px;
      }

      div {
        padding: 5px 10px;
        border-radius: 16px;
        background: #ffffff;
        &.disabled {
          opacity: 0.2;
        }
      }
      .modifyBtn {
        border: 1px solid #436eff;
        color: #436eff;
      }
      .cancelBtn {
        border: 1px solid #ff2d55;
        color: #ff2d55;
      }
    }
  }
}
</style>
