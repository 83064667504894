<template>
  <div class="commented">
    <div class="box">
      <div class="head">
        <span>评价</span>
        <div class="close" @click="_closeCommented">
          <img src="../../assets/imgs/close2.png" />
        </div>
      </div>
      <div class="content">
        <div class="p1">您对该笔订单满意吗？</div>
        <div class="p2">
          <img
            v-for="(item, index) in star"
            :key="index"
            :src="item"
            @click="changeRate(index)"
          />
        </div>
        <template v-if="rate">
          <div class="p3">{{ starTXT }}</div>
          <div class="p4">
            <textarea
              placeholder="邀请您输入评价（对商家、菜品口味、价格是否满意、是否愿意推荐、对无人车服务的建议等）"
              fixed
              :value="value"
              maxlength="100"
            ></textarea>
            <div class="cursor">{{ cursor }}/100</div>
          </div>
        </template>
        <div class="btnBox">
          <div class="btn closeBtn">暂不评价</div>
          <div :class="rate ? 'btn active' : 'btn'">提交评价</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderCard',

  data() {
    return {
      rate: 0, //当前星星数
      cursor: 0, //当前文字数量
      value: '', //评价内容
    };
  },

  props: {
    show: {
      type: Boolean,
    },
  },

  computed: {
    star() {
      let arr = [
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_check.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_check.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_check.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_check.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_check.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_uncheck.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_uncheck.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_uncheck.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_uncheck.png',
        'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/star_uncheck.png',
      ];
      return arr.slice(5 - this.rate, 10 - this.rate);
    },
    starTXT() {
      let obj = {
        0: '未选择',
        1: '非常不满意',
        2: '不太满意',
        3: '一般般',
        4: '很满意',
        5: '超级满意',
      };
      return obj[this.rate];
    },
  },

  watch: {},

  mounted: function () {
    console.log(this.rate);
  },

  methods: {
    _closeCommented() {
      this.closeCommented();
      this.$emit('closeCommentedBox');
    },
    changeRate(index) {
      this.rate = index + 1;
    },
    changeCommented(event) {
      this.value = event.detail.value.slice(0, 100);
      this.cursor = event.detail.cursor > 100 ? 100 : event.detail.cursor;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.commented {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  transition: all 0.2s;
  z-index: 10;

  .box {
    width: 100%;
    background: #ffffff;
    border-radius: 0.32rem 0.32rem 0 0;
    padding: 0 0.4rem;
    margin-bottom: 0rem;

    .head {
      height: 1.08rem;
      border-bottom: 0.01rem solid #f1f1f1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.36rem;
      color: #222222;
      font-weight: bold;
      position: relative;

      .close {
        width: 0.4rem;
        height: 0.4rem;
        position: absolute;
        right: 0;
        top: 0.34rem;
        z-index: 2;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .content {
      padding: 0.5rem 0.4rem 0.48rem;
      display: flex;
      flex-direction: column;
      /*justify-content: center;*/
      align-items: center;

      .p1 {
        font-size: 0.36rem;
        color: #222222;
        font-weight: bold;
        height: 0.5rem;
      }
      .p2 {
        margin-top: 0.48rem;
        height: 0.68rem;
        img {
          width: 0.68rem;
          height: 0.68rem;
          margin-right: 0.3rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      .p3 {
        height: 0.33rem;
        font-size: 0.24rem;
        color: #e71f19;
        margin-top: 0.3rem;
      }
      .p4 {
        width: 100%;
        min-height: 2rem;
        font-size: 28prx;
        background: #f2f2f2;
        border: 0.01rem solid #f1f1f1;
        border-radius: 0.04rem;
        margin-top: 0.4rem;
        position: relative;

        textarea {
          width: 100%;
          height: 2rem;
          padding: 0.2rem 0.2rem 0.6rem 0.2rem;
          box-sizing: border-box;
        }
        .cursor {
          bottom: 0.2rem;
          right: 0.2rem;
          position: absolute;
          font-size: 0.28rem;
          color: #a0a0a0;
        }
      }
      .btnBox {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
      .btn {
        width: 2.35rem;
        /*margin: auto;*/
        height: 0.88rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d7d7d7;
        font-size: 0.32rem;
        font-weight: bold;
        color: #ffffff;
        margin-top: 0.48rem;
        border-radius: 0.54rem;
        box-sizing: border-box;
      }
      .active {
        background: #f6c24a;
        color: #222;
      }
      .closeBtn {
        border: 0.01rem solid #cccccc;
        background: #fff;
        color: #666;
      }
    }
  }
}
</style>
